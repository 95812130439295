<template>
  <div class="home">
    <div class="info">
      <div class="info-wrap">
        <!--        <div class="new">NEW</div>-->
        <div class="row-between">
          <div style="display: flex" @click="toPage('user')">
            <div class="avatar">
              <img
                :src="
                  user.avatar !== '0'
                    ? user.avatar
                    : require('../assets/img/user.png')
                "
                width="100%"
                height="100%"
              />
            </div>
            <div class="name">
              <div class="black bold m-b-5 f-15">{{ user.nickname }}</div>
              <div class="f-11 grey">居民卡编号： {{ user.code }}</div>
            </div>
          </div>
          <div class="sign-in">
            <div class="medal" v-if="user.medal" @click="isShowMedal = true">
              <img
                :src="require('../assets/img/medal-s.png')"
                width="100%"
                height="100%"
              />
            </div>
            <div v-if="user.isSign" class="text-center">
              <img
                :src="require('../assets/img/signIn.png')"
                class="sign-icon"
              />
              <div class="f-9 m-t-5">已签到</div>
            </div>
            <div v-else class="text-center">
              <img
                :src="require('../assets/img/un-signIn.png')"
                @click="SignIn"
                class="sign-icon"
              />
              <div class="f-9 m-t-5">今日未签到</div>
            </div>
          </div>
        </div>
        <!--        <van-progress :percentage="user.stone/500*100" :show-pivot="false"-->
        <!--                      track-color="rgba(255,255,255,.63)" color="#81B3FF"/>-->
        <!--        <div class="f-10 text-right m-b-5 m-t-10">{{user.stone}}/500元石</div>-->
        <!--        <div class="f-10 text-right">累计获得500元石可解锁银卡</div>-->
      </div>
    </div>
    <div class="wrap">
      <swiper ref="mySwiper" :options="swiperOption" class="first-swiper">
        <swiper-slide>
          <div class="item">
            <div class="white m-b-20">元石：{{ user.stone }}</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item" style="background-color: white">
            <div>元气：{{ user.score }}</div>
            <img
              :src="require('../assets/img/home-vigor.jpg')"
              class="icon"
              style="padding-left: 0; padding-right: 0"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <div>种子：{{ user.seed }}</div>
            <img
              :src="require('../assets/img/zhongzi.png')"
              class="icon"
              style="padding-left: 0; padding-right: 0"
            />
          </div>
        </swiper-slide>
      </swiper>
      <div class="collection">
        <div class="bold black f-17">轻松藏品</div>
        <div class="three11" v-if="hasBenCao || hasJXZ === 2">
          <div class="white f-16">您已有数字藏品</div>
          <div class="white m-b-10 f-16">请下载APP查看</div>
          <div class="f-16 bold downAPP" @click="down">立即下载</div>
        </div>
        <swiper
          :options="swiperOptions"
          ref="mySwiper2"
          v-else
          class="three m-t-25"
        >
          <swiper-slide>
            <div class="item">
              <!--              <span class="question" @click="isShowBox = true">-->
              <!--                <img :src="require('../assets/img/question.png')"/>-->
              <!--              </span>-->
              <div class="img"></div>
              <div class="text-center f-12 text white">
                盲盒({{ user.box }})
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item">
              <div class="mask white f-12">暂未开放</div>
              <div class="img"></div>
              <div class="text-center f-12 text">会员权益</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item" @click="toPage('detail')">
              <div class="img"></div>
              <div class="text-center f-12 text white">数字按摩器</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="m-t-50 task">
        <div class="title">
          <span class="bold black f-17">任务中心</span>
          <div @click="isShowService = true">
            <div class="f-12 kefu">
              <span class="grey f-13">联系我们</span>
              <img
                :src="require('../assets/img/code.png')"
                class="code"
                @click="isShowService = true"
              />
            </div>
          </div>
        </div>
        <!--        <div class="task-item" @click="toPage('share')">-->
        <!--          <div class="left">-->
        <!--            <div class="task-icon">-->
        <!--              <img :src="require('../assets/img/task1.png')" width="100%" height="100%">-->
        <!--            </div>-->
        <!--            <div>-->
        <!--              <div class="bold black m-b-5">邀请好友完成预约</div>-->
        <!--              <div class="grey f-13">邀请好友成功预约 +{{invitedNum}}元石</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <van-icon name="arrow" class="black" size="20px"></van-icon>-->
        <!--        </div>-->
        <div class="task-item" @click="toPage('benCaoShare')">
          <div class="left">
            <div class="task-icon">
              <img
                :src="require('../assets/img/task7.png')"
                width="100%"
                height="100%"
              />
            </div>
            <div>
              <div class="bold black m-b-5">邀请好友成功预约本草纲目抢购</div>
              <div class="grey f-13">
                新好友成功预约抢购 +{{ benCaoNumber }}元石
              </div>
            </div>
          </div>
          <van-icon name="arrow" class="black" size="20px"></van-icon>
        </div>
        <div class="task-item" @click="toPage('subscriedShare')">
          <div class="left">
            <div class="task-icon">
              <img
                :src="require('../assets/img/task5.png')"
                width="100%"
                height="100%"
              />
            </div>
            <div>
              <div class="bold black m-b-5">邀请好友预约种子抢购</div>
              <div class="grey f-13">好友成功预约+{{ subscriedNum }}元石</div>
            </div>
          </div>
          <van-icon name="arrow" class="black" size="20px"></van-icon>
        </div>
        <!--        <div class="task-item" @click="toPage('seedShare')">-->
        <!--          <div class="left">-->
        <!--            <div class="task-icon">-->
        <!--              <img-->
        <!--                :src="require('../assets/img/task5.png')"-->
        <!--                width="100%"-->
        <!--                height="100%"-->
        <!--              />-->
        <!--            </div>-->
        <!--            <div>-->
        <!--              <div class="bold black m-b-5">邀请好友抢购种子</div>-->
        <!--              <div class="grey f-13">-->
        <!--                好友成功抢购种子 +{{ seedInvitedNum }}元石-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <van-icon name="arrow" class="black" size="20px"></van-icon>-->
        <!--        </div>-->
        <div class="task-item">
          <div class="left">
            <div class="task-icon">
              <img
                :src="require('../assets/img/task3.png')"
                width="100%"
                height="100%"
              />
            </div>
            <div>
              <div class="bold black m-b-5">
                连续7日签到（{{ user.day }}/7）
              </div>
              <div class="grey f-13">+{{ signNum }}元石</div>
            </div>
          </div>
          <div class="get grey f-13" @click="getGift" v-if="user.day < 7">
            领取
          </div>
          <div class="get grey f-13" v-else>已领取</div>
        </div>
        <div class="task-item" @click="toPage('user')">
          <div class="left">
            <div class="task-icon">
              <img
                :src="require('../assets/img/task2.png')"
                width="100%"
                height="100%"
              />
            </div>
            <div>
              <div class="bold black m-b-5">完善资料</div>
              <div class="grey f-13">+{{ infoNum }}元石</div>
            </div>
          </div>
          <div class="grey" v-if="user.sex !== 4">已完成</div>
          <van-icon name="arrow" class="black" size="20px" v-else></van-icon>
        </div>
        <div class="task-item" v-if="user.isAttested">
          <div class="left">
            <div class="task-icon">
              <img
                :src="require('../assets/img/task4.png')"
                width="100%"
                height="100%"
              />
            </div>
            <div>
              <div class="bold black m-b-5">实名认证</div>
              <div class="grey f-11">实名认证后才能正常购买并体验数字藏品</div>
            </div>
          </div>
          <div class="grey">已完成</div>
        </div>
        <!-- <div class="task-item" @click="toPage('attest')" v-else> -->
        <div class="task-item" @click="toappSM()" v-else>
          <div class="left">
            <div class="task-icon">
              <img
                :src="require('../assets/img/task4.png')"
                width="100%"
                height="100%"
              />
            </div>
            <div>
              <div class="bold black m-b-5">实名认证</div>
              <div class="grey f-11">实名认证后才能正常购买并体验数字藏品</div>
            </div>
          </div>
          <!-- <van-icon name="arrow" class="black" size="20px"></van-icon> -->
        </div>
        <div class="task-item" @click="toPage('user')">
          <div class="left">
            <div class="task-icon">
              <img
                :src="require('../assets/img/task6.png')"
                width="100%"
                height="100%"
              />
            </div>
            <div>
              <div class="bold black m-b-5">完善生日信息</div>
              <div class="grey f-13">
                将用于铸造生成专属藏品 & +{{ baZiNumber }}元石
              </div>
            </div>
          </div>
          <div class="grey" v-if="user.baZi">已完成</div>
          <van-icon name="arrow" class="black" size="20px" v-else></van-icon>
        </div>
        <!--        <div class="task-item" v-if="user.hasGroup">-->
        <!--          <div class="left">-->
        <!--            <div class="task-icon">-->
        <!--              <img-->
        <!--                :src="require('../assets/img/task8.png')"-->
        <!--                width="100%"-->
        <!--                height="100%"-->
        <!--              />-->
        <!--            </div>-->
        <!--            <div>-->
        <!--              <div class="bold black m-b-5">进入社区发送对应口令</div>-->
        <!--              <div class="grey f-13">+{{ joinGroupNumber }}元石</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="grey">已完成</div>-->
        <!--        </div>-->
        <!--        <div class="task-item" @click="addGroup" v-else>-->
        <!--          <div class="left">-->
        <!--            <div class="task-icon">-->
        <!--              <img-->
        <!--                :src="require('../assets/img/task8.png')"-->
        <!--                width="100%"-->
        <!--                height="100%"-->
        <!--              />-->
        <!--            </div>-->
        <!--            <div>-->
        <!--              <div class="bold black m-b-5">进入社区发送对应口令</div>-->
        <!--              <div class="grey f-13">+{{ joinGroupNumber }}元石</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="grey">去完成</div>-->
        <!--        </div>-->
      </div>
      <div class="m-t-50 convert m-b-20">
        <div class="convert-title">
          <div class="bold black f-17">轻松兑换</div>
          <div v-if="hasBenCao || hasJXZ === 2">
            <div class="text-right web-font f-12">已有盲盒：{{ user.box }}</div>
            <div class="f-10 grey text-right">盲盒玩法上线后可开启</div>
          </div>
        </div>
        <div class="content">
          <div style="width: 20px; height: 20px"></div>
          <div class="convert-btn" @click="toPage('integral')">去兑换</div>
        </div>
      </div>
    </div>
    <!--    <van-button block round @click="logout" class="logout">退出登录</van-button>-->
    <tabbar :active="2"></tabbar>
    <van-overlay :show="isShowService" @click="isShowService = false">
      <div class="wrapper">
        <div class="block kefu-dlg">
          <div class="close">
            <van-icon
              name="cross"
              size="15"
              color="#69686D"
              @click="isShowService = false"
            />
          </div>
          <div class="service-code">
            <img
              :src="require('../assets/img/kefy2.png')"
              width="100%"
              height="100%"
            />
          </div>
          <div class="text-center f-15">
            欢迎扫码添加轻松小镇用户社区成员
            <br />
            我们将在1天内给您答复
          </div>
        </div>
      </div>
    </van-overlay>
    <!--  宝箱介绍  -->
    <van-overlay :show="isShowBox" @click="isShowBox = false">
      <div class="wrapper">
        <div class="block box">
          <div class="close">
            <van-icon
              name="cross"
              size="15"
              color="#69686D"
              @click="isShowBox = false"
            />
          </div>
          <div class="m-b-20 f-22 bold">轻松盲盒</div>
          <div class="f-15 m-b-40">
            以元石精粹打造的神秘盲盒，里面可以随机开出数量不定的元气或者元气碎片，元气碎片可用于合成元气。
          </div>
          <div class="f-15 bold m-b-10">如何获取</div>
          <div>元石兑换｜参与预约</div>
        </div>
      </div>
    </van-overlay>
    <!--  宝箱开启  -->
    <van-overlay :show="isShowOpen" @click="isShowOpen = false">
      <div class="wrapper">
        <div class="block open-box">
          <div class="close">
            <van-icon
              name="cross"
              size="15"
              color="#69686D"
              @click="isShowOpen = false"
            />
          </div>
          <template v-if="isGet">
            <div class="f-22 m-b-10 bold text-center">开启盲盒</div>
            <div class="f-15 text-center">获得300元气</div>
            <div class="get">
              <img
                :src="require('../assets/img/vigor-small.png')"
                width="100%"
                height="100%"
              />
            </div>
            <div class="f-13 text-center">剩余盲盒：3</div>
            <div class="box-btn">
              <van-icon name="replay" color="white" />
              再开一个
            </div>
          </template>
          <div
            v-else
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
              height: 100%;
            "
          >
            <div class="f-22 m-b-10 bold text-center">开启失败</div>
            <div class="f-22">盲盒已经开完</div>
            <div class="box-btn">退出</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <she-group-dlg
      :isShow="isShowGroup"
      @close="isShowGroup = false"
    ></she-group-dlg>
    <login
      :show="isShowLogin"
      @close="isShowLogin = false"
      @success="loginSuccess"
    ></login>
    <sign-in
      :show="isShowSign"
      @close="isShowSign = false"
      :pro-data="signData"
    ></sign-in>
    <!--  获得勋章弹窗  -->
    <van-overlay :show="isShowGetMedal" @click="closeGetMedal" z-index="100">
      <div class="getMedal">
        <div class="text-center get-title white bold">恭喜获得</div>
        <div class="medal-group">
          <img :src="require('../assets/img/light.png')" class="light" />
          <img :src="require('../assets/img/medal-l.png')" class="medal-icon" />
        </div>
        <div class="text-center white f-13 medal-des">
          内测特别居民勋章目前仅授予在内测期间
          遇到特殊事件、对平台做出特殊贡献的居民，
          持有本勋章的居民未来可在小镇中享受 特别居民待遇，还将获得
          「内测特别居民勋章」数字藏品
        </div>
        <div class="text-center m-t-25">
          <van-icon
            name="close"
            size="30"
            color="white"
            @click="closeGetMedal"
          />
        </div>
      </div>
    </van-overlay>
    <!-- 勋章详情   -->
    <van-overlay :show="isShowMedal" @click="isShowMedal = false" z-index="100">
      <div class="wrapper">
        <div class="block medal-detail">
          <div class="close">
            <van-icon
              name="cross"
              size="15"
              color="#69686D"
              @click="isShowBox = false"
            />
          </div>
          <div>
            <div class="text-center">
              <img
                :src="require('../assets/img/medal-m.png')"
                class="medal-icon-m"
              />
            </div>
            <div class="medal-detail-des text-center">
              内测特别居民勋章目前仅授予在内测期间
              遇到特殊事件、对平台做出特殊贡献的居民，
              持有本勋章的居民未来可在小镇中享受 特别居民待遇，还将获得
              「内测特别居民勋章」数字藏品
            </div>
            <!--            <div class="text-center look" @click="toPage('medalDetail')">查看详情</div>-->
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Tabbar from "../components/tabbar";
import Login from "../components/Login";
import SignIn from "../components/SignIn";
import SheGroupDlg from "../components/SheGroupDlg";
import { mapMutations, mapState } from "vuex";
import { userApi, signInApi, configApi } from "../api/user";
import { getWxConfig } from "../../tool";
let vm = "";

export default {
  name: "Home",
  components: { Swiper, SwiperSlide, Tabbar, Login, SignIn, SheGroupDlg },
  data() {
    return {
      invitedNum: 0,
      infoNum: 0,
      signNum: 0,
      seedInvitedNum: 0,
      subscriedNum: 29,
      guessNumber: 19,
      baZiNumber: 19,
      benCaoNumber: 15,
      joinGroupNumber: 15,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      swiperOption: {
        watchSlidesProgress: true,
        slidesPerView: 1.85,
        centeredSlides: true,
        loop: true,
        loopedSlides: 5,
        touchRatio: 1, //  触摸距离与slide滑动距离的比率。
        spaceBetween: 0,
        preventLinksPropagation: false,
        on: {
          progress: function (progress) {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = Math.round(this.slides[i].progress);
              if (Math.round(slideProgress) < 0) {
                slide.transform("scale(0.8) translateX(-62%)");
              } else if (Math.round(slideProgress) > 0) {
                slide.transform("scale(0.8) translateX(62%)");
              } else {
                slide.transform("scale(1) translateX(0)");
              }
              const zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
              slide.css("zIndex", zIndex);
            }
          },
          setTransition: function (transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              slide.transition(transition);
            }
          },
          click: function () {
            if (this.clickedIndex % 3 === 0) {
              vm.toPage("energy");
            } else if (this.clickedIndex % 3 === 1) {
              vm.toPage("vigor");
            } else {
              vm.toPage("seed");
            }
          },
        },
      },
      isShowLogin: false,
      isShowSign: false,
      isShowService: false,
      isShowBox: false, //  宝箱介绍
      isShowOpen: false,
      isShowGroup: false,
      isGet: false,
      signData: {},
      isShowGetMedal: false,
      isShowMedal: false,
      result: {},
      hasBenCao: false,
      hasJXZ: 1,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
    }),
  },
  async created() {
    vm = this;
    this.getConfig();
    await this.getUser();
    this.showGetMedal();
    getWxConfig("轻松小镇", "个人中心");
  },
  methods: {
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetSignIn: "setSignIn",
    }),
    down() {
      window.location.href = "http://app.relaverse.cn/m/download.html";
    },
    loginSuccess() {
      this.isShowLogin = false;
      this.showGetMedal();
    },
    toappSM() {
      this.$toast("前往app认证获得元石奖励");
    },
    showGetMedal() {
      if (this.user.medal && !localStorage.getItem("hasMedal")) {
        this.isShowGetMedal = true;
      }
    },
    async getConfig() {
      try {
        const res = await configApi({});
        this.invitedNum = res.data.join;
        this.infoNum = res.data.certification;
        this.signNum = res.data.store;
        this.seedInvitedNum = res.data.seed;
        this.subscriedNum = res.data.seed_subscribe;
        this.guessNumber = res.data.upuser_guess;
        this.baZiNumber = res.data.bazi;
        this.benCaoNumber = res.data.new_user_join;
        this.joinGroupNumber = res.data.join_wx;
      } catch (e) {
        console.log(e);
      }
    },
    async getUser() {
      try {
        const res = await userApi({});
        this.hasBenCao = res.data.mugwort_nft || false;
        this.hasJXZ = res.data.user.is_seckill;
        this.vxSetUser({
          day: res.data.day,
          join_reserve: res.data.join_reserve,
          isSign: res.data.signs,
          ...res.data.user,
        });
      } catch (e) {
        console.log(e);
        if (e.code === 201) {
          this.isShowLogin = true;
        }
      }
    },
    async SignIn() {
      if (!this.isLogin) {
        this.isShowLogin = true;
        return;
      }
      try {
        const res = await signInApi({});
        this.signData = {
          day: res.data.day,
          box: res.data.box,
          stone: res.data.store,
        };
        this.vxSetSignIn(true);
        this.isShowSign = true;
        this.getUser();
        console.log(res);
      } catch (e) {
        console.log(e);
        this.$toast(e.data);
      }
    },
    toPage(name) {
      if (this.isLogin) {
        this.$router.push({ name });
      } else {
        this.isShowLogin = true;
      }
    },
    showBOx() {},
    openBox() {
      this.$toast("未到开启时间，敬请期待");
    },
    getGift() {
      if (this.user.day < 7) {
        this.$toast("连续签到7天即可领取");
      } else if (this.user.day === 7) {
        this.SignIn();
      }
    },
    closeGetMedal() {
      this.isShowGetMedal = false;
      localStorage.setItem("hasMedal", true);
    },
    addGroup() {
      if (this.isLogin) {
        this.isShowGroup = true;
      } else {
        this.isShowLogin = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f7f7f7;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .info {
    width: 100vw;
    height: 184px;
    background-image: url("../assets/img/home-bg.png");
    padding: 65px 18px;
    background-repeat: no-repeat;
    background-size: cover;
    .info-wrap {
      position: relative;
      border-radius: 10px;
      padding: 22px 14px 22px;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.5)
      );
      border: 1px solid rgba(255, 255, 255, 0.5);
      overflow: hidden;
      .new {
        position: absolute;
        left: 0;
        bottom: -14px;
        font-size: 59px;
        color: rgba(255, 255, 255, 0.8);
        font-style: oblique;
      }
      :after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        filter: blur(2px);
        left: 0;
        top: 0;
        z-index: -1;
      }
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: gainsboro;
        margin-right: 10px;
        display: inline-block;
        overflow: hidden;
      }
      .sign-in {
        display: flex;
        align-items: center;
        font-size: 9px;
        .medal {
          width: 40px;
          height: 39px;
          margin-right: 10px;
        }
        .sign-icon {
          width: 27px;
          height: 26px;
        }
      }
    }
  }
  .wrap {
    padding: 40px 18px 0;
  }
  .first-swiper {
    height: 260px;
    /*overflow: hidden;*/
    .swiper-slide {
      &:nth-child(3n + 1) {
        .item {
          background: url("../assets/img/home-stone.png") no-repeat;
          background-size: cover;
        }
      }
      .item {
        width: 100%;
        height: 100%;
        border-radius: 13px;
        background-color: white;
        overflow: hidden;
        padding: 28px 15px;
        position: relative;
        > .mask {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
        }
        .icon {
          width: 100%;
          margin-top: 30px;
        }
      }
    }
  }
  .collection {
    margin-top: 50px;
    .three11 {
      height: 167px;
      background: #e0e0e0;
      border-radius: 3px;
      background: url("../assets/img/home-cangpin.png") no-repeat;
      background-size: 100% 100%;
      padding: 50px 30px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .downAPP {
        padding: 5px 10px;
        background-color: white;
        border-radius: 25px;
        width: 110px;
        color: #aea1e5;
        text-align: center;
      }
    }
    .three {
      .swiper-slide {
        &:first-child {
          .item {
            background: url("../assets/img/manghe.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        &:nth-child(2) {
          .item {
            background: url("../assets/img/vip.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        &:nth-child(3) {
          .item {
            background: url("../assets/img/anmoqi.jpg") no-repeat;
            background-size: 100% 100%;
          }
        }
        .item {
          background-color: white;
          border-radius: 7px;
          padding: 4px 4px 10px;
          position: relative;
          height: 120px;
          .question {
            position: absolute;
            width: 13px;
            height: 13px;
            background: #ececec;
            border-radius: 50%;
            top: 7px;
            right: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 8px;
              height: 8px;
            }
          }
          .img {
            width: 100%;
            height: 84px;
          }
          .text {
            padding: 5px 12px;
          }
          .mask {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.56);
            border-radius: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .task {
    .title {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .kefu {
        display: flex;
        align-items: center;
      }
      .code {
        margin-left: 5px;
        width: 16px;
        height: 16px;
      }
    }
    .task-item {
      height: 70px;
      background: rgba(255, 255, 255, 0.92);
      border-radius: 13px;
      margin-bottom: 13px;
      padding: 15px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      .left {
        display: flex;
        align-items: center;
        .task-icon {
          width: 27px;
          height: 27px;
          margin-right: 10px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .get {
        border: 1px solid #adacb3;
        border-radius: 20px;
        padding: 3px 15px;
        text-align: center;
      }
    }
  }

  .convert {
    margin-bottom: 40px;
    .convert-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content {
      position: relative;
      margin-top: 24px;
      height: 150px;
      border-radius: 17px;
      padding: 23px;
      background: url("../assets/img/convert-bg.jpg") no-repeat;
      background-size: cover;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .convert-btn {
        width: 87px;
        height: 38px;
        line-height: 38px;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 19px;
        font-size: 17px;
        text-align: center;
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 85%;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      .close {
        position: absolute;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #ececec;
        right: 10px;
        top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .kefu-dlg {
      padding: 69px 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      .service-code {
        width: 175px;
        height: 175px;
        margin-bottom: 20px;
      }
    }
  }

  .box {
    padding: 69px 60px;
    .block {
      background: url("../assets/img/dilog-bg.png") no-repeat;
      background-size: cover;
      padding: 68px 36px;
    }
  }

  .open-box {
    color: black;
    padding: 41px 20px 30px;
    height: 345px;
    > .get {
      width: 168px;
      height: 145px;
      margin: 0 auto;
    }
    .box-btn {
      width: 121px;
      height: 38px;
      background: #0a0a0a;
      border-radius: 19px;
      line-height: 38px;
      margin: 15px auto 0;
      text-align: center;
      font-size: 17px;
      color: white;
    }
  }

  .getMedal {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .get-title {
      font-size: 30px;
    }
    .medal-group {
      position: relative;
      text-align: center;
      margin-bottom: 20px;
      .light {
        display: block;
        position: absolute;
        width: 100%;
        animation: move 5s linear infinite;
        z-index: -1;
        @keyframes move {
          from {
            transform: rotate(0);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
      .medal-icon {
        display: inline-block;
        width: 70%;
        margin-top: 30px;
      }
    }
    .medal-des {
      padding: 0 50px;
      line-height: 24px;
    }
  }

  .medal-detail {
    padding: 30px 40px;
    .medal-icon-m {
      width: 60%;
    }
    .medal-detail-des {
      line-height: 24px;
    }
    .look {
      margin: 20px auto 0;
      height: 38px;
      line-height: 38px;
      background: #000000;
      border: 1px solid #000000;
      border-radius: 19px;
      color: white;
      width: 150px;
    }
  }
}
</style>
